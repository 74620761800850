import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translations from './translations'

/**
 * i18n expects a json with languages as keys, and translation objects as values
 */
const resources = Object.entries(translations).reduce(
  (acc, [language, translation]) => ({
    ...acc,
    [language]: { translation },
  }),
  {},
)

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
