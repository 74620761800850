/* eslint-disable prefer-destructuring,no-param-reassign,no-console */
import pickBy from 'lodash/pickBy'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import { createBrowserHistory } from 'history'
// import { trackNavigation } from './lib/tracking'

const history = createBrowserHistory()
const cloneWindowLocation = () => pickBy(window.location, isString)
let currentLocation = cloneWindowLocation()
const stack = [currentLocation]

/**
 * Pathnames can be difficult to get right because they have a lot of variations.  Therefore,
 * normalize our version to something absolute.
 *
 * @param {string} urlFragment
 * @returns {string}
 */
function getNormalizedPathname(urlFragment) {
  const url = new URL(urlFragment, window.location.href)
  return url.pathname
}

/**
 * Gets the href of an element, if it or a parent has one.
 *
 * Some A tags have hrefs, but some don't.
 *
 * @param {Element} target
 * @returns {false|string}
 */
function getHRefOfTarget(target) {
  const anchor = target.tagName !== 'A' ? target.closest('a') : target
  return !!anchor && anchor.getAttribute('href')
}

function isAbsoluteUrl(href) {
  return !!href.match(/^(https?:\/\/)|(mailto:)|(tel:)/)
}

function isSameOrigin(href) {
  return href.indexOf(window.location.origin) === 0
}

export function isExternalWebsite(href) {
  return isAbsoluteUrl(href) && !isSameOrigin(href)
}

/**
 * Every unhandled click on the page will be handled by this function.
 *
 * This is the Core function of all SPA apps because it changes A element clicks to History API push states.
 *
 * @param {MouseEvent} e
 */
function handleClick(e) {
  const href = getHRefOfTarget(e.target)

  if (!href) {
    return
  }

  // trackNavigation({ to: href, from: window?.location?.pathname })

  if (isExternalWebsite(href)) {
    // allow regular navigation
    return
  }

  const destinationPathname = getNormalizedPathname(href)

  // fake navigation with push state (History API)
  e.preventDefault()
  history.push(destinationPathname)
}

export function navigate(href, state) {
  if (!href || !isString(href)) {
    console.warn('href must be a string')
    return
  }

  if (state && !isObject(state)) {
    console.warn('state must be an object')
    return
  }

  // trackNavigation({ to: href, from: window?.location?.pathname })

  if (isExternalWebsite(href)) {
    window.open(href, '_blank')
  } else {
    // https://github.com/ReactTraining/history/blob/v4/docs/Navigation.md
    history.push(href, state)
  }
}

/**
 * Start listening for history changes
 */
export function start() {
  document.addEventListener('click', handleClick)
  history.listen((_, action) => {
    currentLocation = cloneWindowLocation()
    stack.push(currentLocation)
    if (action === 'PUSH') {
      window.scrollTo(0, 0)
    }
  })
}

/**
 * Scrolls to an element who's id matches location.hash
 *
 * To be called within a component that uses an id for an anchor link
 *
 * @param elId {string} Id of element to scroll to. Defaults to window.location.hash
 * @param options {object} scrollIntoViewOptions object that is passed into the scrollIntoView Web API function. See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 */
export function scrollToAnchorLinkElement(
  elId = window.location.hash,
  options = { behavior: 'smooth', block: 'center', inline: 'center' },
) {
  if (!elId || !document.querySelector(elId)) return
  document.querySelector(elId).scrollIntoView(options)
}

export default history
