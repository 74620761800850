import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import { acceptInvitation } from '../../apis/accountService.apis'

const MEMBER_WEB_APP = process.env.REACT_APP_MEMBER_WEB_URI || ''

interface IProps {
  uuid: string
}

export const AcceptButton = ({ uuid }: IProps) => {
  const auth0 = useAuth0()
  const { t } = useTranslation()
  const [accepting, setAccepting] = useState(false)
  const label = accepting
    ? t('invitation.accepting', 'Accepting...')
    : t('invitation.accept', 'Accept invite')

  const submit = async (e: any) => {
    e.preventDefault()
    const jwt = await auth0.getAccessTokenSilently()
    setAccepting(true)
    return acceptInvitation(jwt, uuid).then(() => {
      window.location.href = MEMBER_WEB_APP
    })
  }

  return (
    <button
      className="ray-button ray-button--primary"
      disabled={accepting}
      onClick={submit}
    >
      {label}
    </button>
  )
}
