import React from 'react'
import UnauthedPage from './pages/unauthed_page/UnauthedPage'
import { Home } from './pages/home/Home'
import { Invitations } from './components/invitations/Invitations.components'

export interface PageRoute {
  path: string
  canBeViewedAsUnauthenticated?: boolean
  page: React.FunctionComponent<any>
}

export const homeRoute = '/home'

export const routes: PageRoute[] = [
  {
    path: '/unauthed_page',
    canBeViewedAsUnauthenticated: true,
    page: UnauthedPage,
  },
  {
    path: '/invitations/:uuid',
    page: Invitations,
  },
  { path: homeRoute, page: Home },
  // { path: '/', page: Home },
]
