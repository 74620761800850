import requireContext from 'require-context.macro'

const TRANSLATION_FILENAME_PATTERN = /.\/|.json/g

/**
 * Dynamically load all translation json files in the translations directory
 */
const context = requireContext('./', false, /\.json$/)
const keys = context.keys()
const values = keys.map(context)

export default keys.reduce((translations, filename, index) => {
  const language = filename.replace(TRANSLATION_FILENAME_PATTERN, '')
  const translation = values[index]
  return {
    ...translations,
    [language]: translation,
    [language.slice(0, 2)]: translation, // fallback keys for two-letter locales
  }
}, {})
