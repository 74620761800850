import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Switch, Route } from 'react-router-dom'
import '@wework/ray-core'

import 'focus-visible'

import { routes } from './routes'
import HeaderContainer from './components/HeaderContainer'

const renderedRoutes = routes.map(route => {
  const component = route.canBeViewedAsUnauthenticated
    ? route.page
    : withAuthenticationRequired(route.page)

  return <Route path={route.path} key={route.path} component={component} />
})

const App: React.FunctionComponent = () => {
  return (
    <>
      <HeaderContainer />
      <Switch>{renderedRoutes}</Switch>
    </>
  )
}

export default App
