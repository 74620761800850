import React from 'react'
import { Link } from 'react-router-dom'

const Login: React.FunctionComponent = () => {
  return (
    <div>
      <p>This is an example unauthed page!</p>
      <p>
        <Link to="/">Return Home</Link>
      </p>
    </div>
  )
}

export default Login
