import React from 'react'
import './header-logo.css'

export const HeaderLogo = () => {
  return (
    <div className="header-logo">
      <a href="/">
        <div className="header-logo--image" />
      </a>
      <div className={`ray-text--body header-logo--text`}>Member</div>
    </div>
  )
}

HeaderLogo.displayName = 'HeaderLogo'

export const HeaderContainer = () => {
  return (
    <div className="ray-page-container general-header">
      <div className="logo">
        <HeaderLogo />
      </div>
    </div>
  )
}

export default HeaderContainer
