import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import history from './history'
import './i18n/config'

const onRedirectCallback = (appState: any) => {
  // Use the router's history module to replace the url
  history.replace(appState.returnTo || window.location.pathname)
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || 'idp-staging.weworkers.io'}
      clientId={
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        'NRCdz5W7qcfeuZdadXVdj1XH0XdrsnP0'
      }
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="wework"
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <Router history={history}>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
