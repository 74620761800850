const HOST =
  process.env.REACT_APP_ACCOUNT_SERVICE_URI ||
  'https://accounts-account-service-staging.phoenix.dev.wwrk.co'

export const getInvitationCompanyName = async (
  jwt: string,
  invitationUuid: string,
) => {
  try {
    const response = await fetch(`${HOST}/v1/invitations/${invitationUuid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    if (response.status !== 200) {
      throw new Error(`${response.status}`)
    }

    return response.json()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const acceptInvitation = async (jwt: string, invitationUuid: string) => {
  try {
    const response = await fetch(`${HOST}/v1/invitations/${invitationUuid}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    return response.json()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
