import React, { useState, useEffect } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'

import { getInvitationCompanyName } from '../../apis/accountService.apis'
import { AcceptButton } from './AcceptButton.components'
import './invitations.css'
import LogoutButton from './LogoutButton.components'

const privacyPolicyUrl = 'https://www.wework.com/legal/global-privacy-policy'

const InvitationError = ({ t }: { t: TFunction<'translation', undefined> }) => (
  <div>
    <h1>{t('invitation.error.title', 'Error')}</h1>
    <p className="ray-text--body-large">
      {t(
        'invitation.error.message',
        'Could not find details for this invitation.',
      )}
    </p>
    <LogoutButton />
  </div>
)

const InvitationLoading = ({
  t,
}: {
  t: TFunction<'translation', undefined>
}) => (
  <div>
    <h1>{t('invitation.loading.title', 'Loading...')}</h1>
  </div>
)

const Invitation = ({
  companyName,
  t,
  uuid,
}: {
  companyName: string | null
  t: TFunction<'translation', undefined>
  uuid: string
}) => (
  <div>
    <h1>{t('invitation.greeting', 'Welcome back!')}</h1>
    <p className="ray-text--body-large">
      {t('invitation.join_company', {
        defaultValue: "You've been invited to join {{companyName}}'s WeWork.",
        companyName,
      })}
      <br />
      {t('invitation.get_started', 'Click "Accept invite" to get started.')}
    </p>
    <p className="ray-text--body-small">
      By clicking the &quot;Accept Invite&quot; button below, you acknowledge
      our{' '}
      <a
        key="privacyPolicy"
        href={privacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Global Privacy Policy
      </a>
      .
    </p>
    <AcceptButton uuid={uuid} />
  </div>
)

export const Invitations: React.FunctionComponent = () => {
  const params = useParams<{ uuid: string }>()
  const { uuid = '' } = params
  const auth0 = useAuth0()
  const { t } = useTranslation()
  const [companyName, setCompanyName] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const asyncFunc = async () => {
      const jwt = await auth0.getAccessTokenSilently()
      getInvitationCompanyName(jwt, uuid)
        .then(response => {
          setCompanyName(response?.account?.name)
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncFunc()
  }, [])

  const child = () => {
    if (loading) {
      return <InvitationLoading t={t} />
    }

    if (companyName) {
      return <Invitation companyName={companyName} t={t} uuid={uuid} />
    }
    return <InvitationError t={t} />
  }

  return (
    <main className="invitations ray-page-container">
      <>{child()}</>
    </main>
  )
}
